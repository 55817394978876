import { HomepageMedia } from '../design/HomapageMedia';
import { HomepageStyle } from '../design/HomepageStyle';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import Image from 'next/image';

export const Partner = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });

    return (
        <HomepageStyle.Card order={1} height={552}>
            <HomepageStyle.CardTitle dangerouslySetInnerHTML={{ __html: t.home.PARTNER_TITLE }} />
            <Slider>
                <SliderContents locale={locale} />
                <SliderContents locale={locale} />
            </Slider>
        </HomepageStyle.Card>
    );
};

const SliderContents = ({ locale }: { locale: Locales }) => {
    const { t } = useTranslate({ locale });
    return (
        <List>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_1.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'채널톡'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_1 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_2.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'스마일샤크'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_2 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_3.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'레몬베이스'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_3 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_4.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'어센트코리아'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_4 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_5.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'zuzu'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_5 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_6.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'디스콰이엇'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_6 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_7.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'리캐치'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_7 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_8.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'캐시노트'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_8 }} />
            </Item>
            <Item>
                <Logo>
                    <Image
                        src={require('../assets/image/partner_9.png')}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt={'그리팅'}
                    />
                </Logo>
                <p dangerouslySetInnerHTML={{ __html: t.home.PARTNER_SAMPLE_9 }} />
            </Item>
        </List>
    );
};

const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const Slider = styled.div`
    width: 100%;
    display: flex;
    margin: 100px 0 32px 0;
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    ${HomepageMedia.forMobile(`
        margin: 24px 0 0 0;
    `)}
    &:hover > ul {
        animation-play-state: paused;
    }
`;

const List = styled.ul`
    list-style: none;
    display: flex;
    animation: ${slide} 20s linear infinite;
`;

const Item = styled.li`
    padding: 15px;
    margin-right: 32px;
    user-select: none;
    -webkit-user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    ${HomepageMedia.forMobile(`
        margin-right: 8px;
    `)}
    p {
        color: #222;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 400;
        white-space: nowrap;
        ${HomepageMedia.forMobile(`
            font-size: 0.875rem;
            gap: 14px;
        `)}
    }
`;

const Logo = styled.div`
    width: 120px;
    height: 120px;
    ${HomepageMedia.forMobile(`
        width: 72px;
        height: 72px;
    `)}
`;
