import { HomepageMedia } from '../design/HomapageMedia';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Locales, useTranslate } from 'callabo-locale/src/hooks/useTranslate';
import { useCallback, useEffect, useRef, useState } from 'react';

export const UsageDemo = ({ locale }: { locale: Locales }): JSX.Element => {
    const { t } = useTranslate({ locale });
    const [selectedItem, setSelectedItem] = useState(0);
    const targetRef0 = useRef<HTMLDivElement>(null);
    const targetRef1 = useRef<HTMLDivElement>(null);
    const targetRef2 = useRef<HTMLDivElement>(null);
    const targetRef3 = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const createObserver = useCallback(
        (target: HTMLDivElement, itemIdx: number, intersectionRatio: number) => {
            const options = {
                root: null,
                threshold: intersectionRatio,
            };
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= intersectionRatio) {
                        target?.scrollIntoView();
                        setSelectedItem(itemIdx);
                    }
                });
            }, options);
            return observer;
        },
        []
    );

    useEffect(() => {
        const observer0 = createObserver(targetRef0.current, 0, 1);
        const observer1 = createObserver(targetRef1.current, 1, 0.6);
        const observer2 = createObserver(targetRef2.current, 2, 0.6);
        const observer3 = createObserver(targetRef3.current, 3, 0.6);

        if (targetRef0.current) {
            observer0.observe(targetRef0.current);
        }
        if (targetRef1.current) {
            observer1.observe(targetRef1.current);
        }
        if (targetRef2.current) {
            observer2.observe(targetRef2.current);
        }
        if (targetRef3.current) {
            observer3.observe(targetRef3.current);
        }
        if (videoRef.current) {
            videoRef.current.disableRemotePlayback = true;
        }
        return () => {
            if (targetRef0.current) {
                observer0.unobserve(targetRef0.current);
            }
            if (targetRef1.current) {
                observer1.unobserve(targetRef1.current);
            }
            if (targetRef2.current) {
                observer2.unobserve(targetRef2.current);
            }
            if (targetRef3.current) {
                observer3.unobserve(targetRef3.current);
            }
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
        }
    }, [selectedItem]);

    return (
        <>
            <Container>
                <Wrapper>
                    <MenuList>
                        <MenuItem
                            dangerouslySetInnerHTML={{
                                __html: t.home.DEMO_MEETING_RETROSPECTIVE,
                            }}
                            onClick={() => {
                                targetRef0.current.scrollIntoView(true);
                                setSelectedItem(0);
                            }}
                            selected={selectedItem === 0}
                        />
                        <MenuItem
                            dangerouslySetInnerHTML={{ __html: t.home.DEMO_MEETING_REPLAY }}
                            onClick={() => {
                                targetRef1.current.scrollIntoView(true);
                                setSelectedItem(1);
                            }}
                            selected={selectedItem === 1}
                        />
                        <MenuItem
                            dangerouslySetInnerHTML={{
                                __html: t.home.DEMO_RECORD_ON_AND_OFF,
                            }}
                            onClick={() => {
                                targetRef2.current.scrollIntoView(true);
                                setSelectedItem(2);
                            }}
                            selected={selectedItem === 2}
                        />
                        <MenuItem
                            dangerouslySetInnerHTML={{ __html: t.home.DEMO_MEETING_SHARE }}
                            onClick={() => {
                                targetRef3.current.scrollIntoView(true);
                                setSelectedItem(3);
                            }}
                            selected={selectedItem === 3}
                        />
                    </MenuList>
                    <DemoVideo>
                        {(SRC_LIST[locale] ?? SRC_LIST['ko'])?.map((src, index) => {
                            return (
                                <video
                                    key={`usageVideo-${locale}-${index}`}
                                    preload="metadata"
                                    loop
                                    muted
                                    autoPlay
                                    playsInline
                                    disableRemotePlayback
                                    style={{
                                        display: selectedItem === index ? 'block' : 'none',
                                    }}
                                    ref={selectedItem === index ? videoRef : null}
                                >
                                    <source src={src[0]} type="video/webm" />
                                    <source src={src[1]} type="video/mp4" />
                                </video>
                            );
                        })}
                    </DemoVideo>
                </Wrapper>
            </Container>
            <div ref={targetRef0} style={{ height: '60vh', width: '100%' }} />
            <div ref={targetRef1} style={{ height: '100vh', width: '100%' }} />
            <div ref={targetRef2} style={{ height: '100vh', width: '100%' }} />
            <div ref={targetRef3} style={{ height: '100vh', width: '100%' }} />
            <div style={{ height: '40vh', width: '100%' }} />
        </>
    );
};

const SRC_LIST = {
    ko: [
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-01-87405ae.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-01-3372502.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-02-9b43031.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-02-64c6c95.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-03-ee23c6e.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-03-ad34498.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-04-4c75196.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/homepage-usage-demo-04-a567c22.mp4',
        ],
    ],
    ja: [
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-01-8abcbb0.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-01-42c39f3.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-02-8602aba.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-02-2b65ff8.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-03-dfa0152.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-03-7617b0e.mp4',
        ],
        [
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-04-0e6e098.webm',
            'https://returnzero-public-image.s3.ap-northeast-2.amazonaws.com/callabo/ja/homepage-usage-demo-04-6035e9d.mp4',
        ],
    ],
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 144px 32px 0 32px;
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 0;
    ${HomepageMedia.forMobile(`
        padding: 96px 12px 0 12px;
        overflow: hidden;
        transform: translateY(-20px);
    `)}
`;

const Wrapper = styled.section`
    display: flex;
    gap: 96px;
    max-height: 100%;
    ${HomepageMedia.forMobile(`
        flex-wrap: wrap;
        justify-content: center;
        gap: 32px;
    `)}
`;

const MenuList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 16px;
    ${HomepageMedia.forMobile(`
        width: 100%;
        max-width: 400px;
        gap: 4px;
        padding: 0 24px;
    `)}
`;

const MenuItem = styled.li<{ selected: boolean }>`
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;

    border-radius: 8px;
    border: 1px solid #e6ecff;
    background: #fff;

    color: #222;
    text-align: center;

    font-size: 1.25rem;
    font-weight: 600;
    white-space: nowrap;
    ${HomepageMedia.forMobile(`
        width: 100%;
        padding: 12px 20px;
        font-size: 0.75rem;
        font-weight: 600;
        border-radius: 4px;
    `)}

    ${({ selected }) =>
        selected &&
        css`
            background: #2e67fe;
            color: #fff;
        `}
`;

const DemoVideo = styled.div`
    border-radius: 56px;
    border: 20px solid #f6f9ff;
    background: #f6f9ff;
    overflow: hidden;

    ${HomepageMedia.forMobile(`
        border-radius: 28px;
        border: 8px solid #f6f9ff;
    `)}

    video {
        object-fit: cover;
        transition: opacity 0.2s ease-in-out;
        ${HomepageMedia.forMobile(`
            height: calc(50vh - 100px);
        `)}
        ${HomepageMedia.forDesktop(`
            height: calc(75vh - 130px);
        `)}
    }
`;
